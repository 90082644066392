export default function NoContentPlaceholder({ text, emoji }) {
  return (
    <div
      className="flex flex-col justify-center items-center"
      style={{ minHeight: "50vh" }}
    >
      <div className="text-center text-xl text-gray-300">{text}</div>
      <div className="text-5xl">{emoji}</div>
    </div>
  );
}
